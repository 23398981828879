import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import bg from '../images/red-blue.png'


const IndexPage = () => (
    <div style={{
      backgroundImage: `url(${bg})`,
      backgroundSize: '25%',
      backgroundRepeat: 'repeat',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <SEO title="Home" />
      <div style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '10vw',
      }}>
        <h1 style={{
          color: 'black',
          fontFamily: 'barlow condensed',
        }}>Coming Soon</h1>
      </div>
    </div>
)

export default IndexPage
